div.content-items div.user-card {
  -moz-box-shadow: 0 0 0.5rem #ccc;
  -webkit-box-shadow: 0 0 0.5rem #ccc;
  box-shadow: 0 0 0.5rem #ccc;
}

div.content-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

div.content-items div.user-card {
  border-radius: 0.5rem;
  background-color: rgb(240, 240, 240);
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
}

div.content-items div.user-card div.user-picture-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

div.content-items div.user-card div.user-picture-container div.user-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 5rem;
  height: 5rem;
  background-color: rgb(225, 225, 225);
  border: 1px solid rgb(215, 215, 215);
}

div.content-items
  div.user-card
  div.user-picture-container
  div.user-picture
  svg {
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;
  color: rgb(150, 150, 150);
}

div.content-items div.user-card div.user-info-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

div.content-items div.user-card div.user-info-container .info-item {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

div.content-items
  div.user-card
  div.user-info-container
  div.user-name-container
  h6 {
  font-weight: 700;
}

div.content-items div.user-card div.user-info-container hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  height: 1px;
}

div.content-items
  div.user-card
  div.user-info-container
  div.admin-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.content-items
  div.user-card
  div.user-info-container
  div.admin-status-container
  .admin-status-item {
  margin: 0 0.2rem;
}

div.content-items
  div.user-card
  div.user-info-container
  div.admin-status-container
  .admin-status-item {
  margin: 0 0.2rem;
}

div.content-items
  div.user-card
  div.user-info-container
  div.admin-status-container
  .admin-status-item.spinner {
  color: rgb(13, 110, 253);
}

#profile-edit-form {
  margin-bottom: 2.5rem;
}

div.profile-edit.form-group {
  margin: 2rem 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.content-items div.user-card {
    width: 20rem;
    margin: 1rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
