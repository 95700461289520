div.data-card {
  -moz-box-shadow: 0 0 0.5rem #ccc;
  -webkit-box-shadow: 0 0 0.5rem #ccc;
  box-shadow: 0 0 0.5rem #ccc;
}

div.data-card {
  background-color: rgb(240, 240, 240);
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

div.data-card div.card-title h6 {
  margin: 0;
  padding: 0;
}

div.data-card div.card-title {
  flex: 1;
}

div.data-card div.card-buttons span.button-text {
  display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.data-card div.card-buttons span.button-text {
    display: inline;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
