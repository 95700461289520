.dashboard-content {
  padding-top: 1rem;
  padding-bottom: 2.5rem;
}

.dashboard-content .dashboard-section {
  -moz-box-shadow: 0 0 0.5rem rgb(200, 200, 200);
  -webkit-box-shadow: 0 0 0.5rem rgb(200, 200, 200);
  box-shadow: 0 0 0.5rem rgb(200, 200, 200);
  overflow-x: hidden;
  position: relative;

  width: 100%;
  background-color: rgb(235, 235, 235);
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.25rem;
}

.dashboard-content .dashboard-section h4 {
  color: rgb(65, 65, 65);
}

.dashboard-content .dashboard-section .section-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-content .dashboard-section .section-content a {
  width: 100%;
  margin: 0.25rem 0;
}

.dashboard-content .dashboard-section .section-content button {
  width: 100%;
  height: 7.5rem;
  margin: 0;
}

.dashboard-content .dashboard-button-content {
  width: 100%;
  height: 100%;
}

.dashboard-button-content {
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
}

.dashboard-button-content span {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.dashboard-button-content svg {
  width: 3rem;
  height: 3rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .dashboard-content .dashboard-section .section-content a {
    width: 10rem;
    height: 10rem;
    margin: 0.5rem;
  }

  .dashboard-content .dashboard-section .section-content button {
    width: 100%;
    height: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
