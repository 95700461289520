div.list-select {
  height: 0;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  max-height: 20rem;
  -moz-box-shadow: 0 0 1rem #ccc;
  -webkit-box-shadow: 0 0 1rem #ccc;
  box-shadow: 0 0 1rem #ccc;
  transition: 0.35s ease-out;
}

div.show-options {
  height: 20rem;
  transition: 0.35s ease-out;
}

div.list-select hr {
  width: 100%;
  margin: 0;
}

div.list-select div.title-bar {
  width: 100%;
  display: flex;
  height: 2.5rem;
  max-height: 2.5rem;
  align-items: center;
  align-content: center;
}

div.list-select div.title-bar h6 {
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}

div.list-select div.option-list {
  width: 100%;
  height: 17.5rem;
  max-height: 17.5rem;
  overflow: hidden;
  overflow-y: scroll;
}

div.list-select div.option-list li {
  list-style: none;
  font-weight: 600;
  color: rgb(75, 75, 75);
  margin: 0.2rem 0;
  text-decoration: underline;
}

div.list-select div.option-list li:hover {
  font-weight: 700;
  color: rgb(75, 85, 95);
}

div.list-select div.option-list li:active {
  font-weight: 600;
  color: rgb(50, 100, 150);
}

div.list-select div.option-list li.item-selected {
  font-weight: 700;
  color: rgb(24, 55, 122);
}

.products-page div.pagination-div {
  display: flex;
  justify-content: center;
}
