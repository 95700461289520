header.admin-header div.brand-container {
  display: flex;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(225, 225, 225);
}

header.admin-header div.brand-container:hover {
  background-color: rgb(215, 220, 225);
}

header.admin-header div.brand-container:active {
  background-color: rgb(205, 210, 215);
}

header.admin-header div.brand-container div.brand-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
}

header.admin-header div.brand-container div.brand-icon svg {
  width: 2rem;
  height: 2rem;
  color: rgb(50, 50, 50);
}

header.admin-header div.brand-container div.brand-text span {
  display: block;
  margin: 0;
  padding: 0;
  color: rgb(50, 50, 50);
  font-weight: 500;
  font-size: 0.85rem;
}

header.admin-header div.login-container div.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

header.admin-header div.login-container div.user-details div.user-name {
  display: none;
  font-weight: 500;
  color: rgb(50, 50, 50);
  margin-right: 0.5rem;
}

header.admin-header div.login-container div.user-details div.user-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  background-color: rgb(225, 225, 225);
  border: 1px solid rgb(200, 200, 200);
}

header.admin-header
  div.login-container
  div.user-details
  div.user-picture:hover {
  background-color: rgb(215, 220, 225);
}

header.admin-header
  div.login-container
  div.user-details
  div.user-picture:active {
  background-color: rgb(205, 210, 215);
}

header.admin-header div.login-container div.user-picture svg {
  width: 80%;
  height: 80%;
  color: rgb(100, 100, 100);
}

header.admin-header div.login-container div.login-text a {
  font-weight: 500;
  color: rgb(50, 50, 50);
  text-decoration: none;
}

header.admin-header div.login-container div.user-details div.user-actions {
  -moz-box-shadow: 0 0 0.5rem #ccc;
  -webkit-box-shadow: 0 0 0.5rem #ccc;
  box-shadow: 0 0 0.5rem #ccc;

  font-weight: 500;
  color: rgb(50, 50, 50);

  border-radius: 0.15rem;
  position: absolute;
  background-color: rgb(240, 240, 240);
  top: 3rem;
  right: 0;
  width: 15rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  height: 7rem;
  overflow: hidden;
  transition: height 0.25s;
}

header.admin-header
  div.login-container
  div.user-details
  div.user-actions.hidden {
  height: 0rem;
}

header.admin-header div.login-container div.user-details div.user-actions a {
  text-decoration: none;
  color: rgb(50, 50, 50);
}

header.admin-header div.login-container div.user-details div.user-actions hr {
  width: 100%;
  margin: 0.1rem 0.5rem;
}

header.admin-header
  div.login-container
  div.user-details
  div.user-actions
  .user-action-item {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0.4rem 1rem;
}

header.admin-header
  div.login-container
  div.user-details
  div.user-actions
  .user-action-item:hover {
  color: rgb(100, 100, 100);
}

header.admin-header
  div.login-container
  div.user-details
  div.user-actions
  .user-action-item:active {
  font-weight: 400;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  header.admin-header div.brand-container div.brand-icon svg {
    width: 3rem;
    height: 3rem;
  }

  header.admin-header div.brand-container div.brand-icon {
    margin-right: 0.5rem;
  }

  header.admin-header div.brand-container div.brand-text span {
    font-size: 1.25rem;
  }

  header.admin-header div.login-container div.user-details div.user-name {
    display: inline;
  }

  header.admin-header div.login-container div.user-details {
    background-color: rgb(225, 225, 225);
    padding: 1rem;
    border-radius: 0.5rem;
  }

  header.admin-header div.login-container div.user-details:hover {
    background-color: rgb(215, 220, 225);
  }

  header.admin-header div.login-container div.user-details:active {
    background-color: rgb(205, 210, 215);
  }

  header.admin-header
    div.login-container
    div.user-details
    div.user-picture:hover {
    background-color: rgb(240, 240, 240);
  }

  header.admin-header
    div.login-container
    div.user-details
    div.user-picture:active {
    background-color: rgb(240, 240, 240);
  }

  header.admin-header div.login-container div.user-details div.user-picture {
    background-color: rgb(240, 240, 240);
  }

  header.admin-header div.login-container div.user-details div.user-actions {
    top: 5rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
