#header-navbar,
footer.page-footer,
section.page-section,
div.product-card,
div.product-card-content {
  -moz-box-shadow: 0 0 1rem #ccc;
  -webkit-box-shadow: 0 0 1rem #ccc;
  box-shadow: 0 0 1rem #ccc;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

section.page-section {
  margin: 2rem auto;
  padding: 1.5rem 1rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
