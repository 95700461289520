span.loading-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: rgb(100, 100, 100);
}

div.loading-title-container {
  margin-top: 1rem;
}

div.loading-page.full-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: snow;
  z-index: 100;
  pointer-events: none;
}

div.loading-page.full-screen .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.smooth-hide {
  opacity: 0;
  animation: smoothHide 1s;
  -o-animation: smoothHide 1s;
  -moz-animation: smoothHide 1s;
  -webkit-animation: smoothHide 1s;
}

.loading-logo-shrink {
  animation: loadingLogoShrink 1s ease-out;
  -o-animation: loadingLogoShrink 1s ease-out;
  -moz-animation: loadingLogoShrink 1s ease-out;
  -webkit-animation: loadingLogoShrink 1s ease-out;
}

@keyframes smoothHide {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes loadingLogoShrink {
  0% {
    width: 300px;
    height: 300px;
  }
  100% {
    width: 275px;
    height: 275px;
  }
}
