div.product-list.container-admin
  div.products-container
  div.product-card-container
  div.product-card {
  -moz-box-shadow: 0 0 1rem #ccc;
  -webkit-box-shadow: 0 0 1rem #ccc;
  box-shadow: 0 0 1rem #ccc;
  overflow: hidden;
}

div.product-modify-photos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

div.product-modify-photos div.photo-container {
  width: 100%;
  margin: 1rem 0;
}

div.product-modify-photos img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-bottom: 0.15rem;
}

div.product-modify-photos img.remove-selected {
  box-shadow: 0 0 0 0.3rem rgb(250, 25, 25);
  outline: none;
  border: none;
  transition: box-shadow 0.1s;
  border-radius: 0.1rem;

  opacity: 0.65;
}

div.product-modify-photos img.main-image {
  box-shadow: 0 0 0 0.3rem rgb(25, 200, 25);
  outline: none;
  border: none;
  transition: box-shadow 0.1s;
  border-radius: 0.1rem;
}

div.product-modify-photos div.photo-modify-buttons {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
div.product-modify-photos div.photo-modify-buttons button {
  width: 100%;
  height: 2.5rem;
  margin: 0.15rem 0;
}

.product-list div.paging-container {
  display: flex;
  justify-content: center;
}

div.product-info-form textarea {
  min-height: 10rem;
}

div.product-list.container-admin div.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem 0;
}

div.product-list.container-admin
  div.products-container
  div.product-card-buttons {
  width: 100%;
}

div.product-list.container-admin
  div.products-container
  div.product-card-buttons
  button {
  width: 50%;
  margin: 0;
}

div.product-list.container-admin
  div.products-container
  div.product-card-container {
  margin: 1rem 0;
}

div.product-list.container-admin
  div.products-container
  div.product-card-container
  div.product-card {
  border-radius: 0.2rem;
}

div.product-list.container-admin
  div.products-container
  div.product-card-container {
  width: 100%;
}

div.product-list.container-admin
  div.products-container
  div.product-card-container
  div.product-card {
  margin: 0;
  width: 100%;
}

div.product-list.container-admin div.filter-select-container {
  position: relative;
  margin-top: 1rem;
}

div.product-list.container-admin div.filter-container {
  display: flex;
  width: 100%;
}

div.product-list.container-admin div.filter-container div.filter-title h5 {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

div.product-list.container-admin div.filter-container div.filter-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

div.product-list.container-admin div.filter-container div.filter-buttons svg {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  transition: 0.2s;
}

div.product-list.container-admin
  div.filter-container
  div.filter-buttons
  div.icon {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  border-radius: 0.4rem;
  background-color: rgb(225, 225, 225);
  overflow: hidden;
  transition: box-shadow 0.1s;
}

div.product-list.container-admin
  div.filter-container
  div.filter-buttons
  div.icon-active {
  -moz-box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
  -webkit-box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
  box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
}

div.product-list.container-admin
  div.filter-container
  div.filter-buttons
  svg:hover {
  padding: 0.45rem;
  background-color: rgb(235, 235, 235);
}

div.product-list.container-admin
  div.filter-container
  div.filter-buttons
  svg:active {
  background-color: rgb(215, 215, 215);
  padding: 0.55rem;
}

div.product-modify-container div.modify-section {
  margin: 1.5rem 0;
}

div.product-modify-container div.modify-section div.modify-sub-section {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: rgb(235, 235, 235);
  border-radius: 0.5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  section.product-preview {
    margin: 5rem auto;
  }

  section.product-preview div.pane {
    width: 49%;
  }

  div.product-card {
    width: 18rem;
    margin: 0.5rem;
  }

  div.product-list.container-admin
    div.products-container
    div.product-card-container {
    width: 18rem;
    margin: 0.5rem;
  }

  div.product-modify-photos div.photo-container {
    -moz-box-shadow: 0 0 0.2rem #ccc;
    -webkit-box-shadow: 0 0 0.2rem #ccc;
    box-shadow: 0 0 0.2rem #ccc;
    overflow: hidden;

    height: 22.5rem; /*photo height + 7.5rem to compensate for buttons*/
    width: auto;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: rgb(215, 215, 215);
  }

  div.product-modify-photos div.single-photo {
    margin-bottom: 0.15rem;
    height: 15rem;
  }

  div.product-modify-photos img {
    max-height: 100%;
    height: 100%;
    width: auto;
    margin: auto;
    display: block;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
