div.main-image-container,
div.full-screen-image-overlay {
  -moz-box-shadow: 0 0 1rem #ccc;
  -webkit-box-shadow: 0 0 1rem #ccc;
  box-shadow: 0 0 1rem #ccc;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

div.image-gallery {
  width: auto;
  width: 100%;
}

div.image-gallery div.main-image-container {
  width: 100%;
  height: auto;
  aspect-ratio: auto 4 / 3;
  overflow: hidden;
  display: flex;
  background-color: rgb(225, 225, 225);
  position: relative;
}

div.image-gallery div.main-image-show {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

div.image-gallery img.main-image {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  position: absolute;
}

div.image-gallery div.thumbnail-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

div.image-gallery .thumbnail-container button,
div.image-gallery .thumbnail-container button:hover,
div.image-gallery .thumbnail-container button:active,
div.image-gallery .thumbnail-container button:focus {
  color: rgb(0, 120, 250);
  background: transparent;
  box-shadow: none !important;
  border: none;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  outline: none !important;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 8rem;
  padding: 0;
  transition: 0.1s;
}

div.image-gallery .thumbnail-container button svg {
  width: 2rem;
  height: 2rem;
}

div.image-gallery .thumbnail-container button svg:active {
  padding: 0.1rem;
}

div.image-gallery div.thumbnails {
  width: 100%;
  height: 10rem;
  padding-top: 2rem;
  padding-bottom: 1rem;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  overflow: hidden;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
}

div.image-gallery div.image-gallery div.thumbnails::-webkit-scrollbar {
  display: none;
}

div.image-gallery img.thumbnail {
  height: 100%;
  margin: 0.5rem;
}

div.image-gallery div.img-spacer {
  height: 100%;
}

div.image-gallery img.selected {
  box-shadow: 0 0 0 0.3rem rgb(75, 150, 250);
  outline: none;
  border: none;
  transition: 0.2s;
  border-radius: 0.1rem;
}

div.image-gallery p.product-modify {
  margin-top: 1rem;
}

div.image-gallery div.no-image {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-content: center;
  color: rgb(150, 150, 150);
  width: 100%;
  height: 100%;
  opacity: 1;
  opacity: 100;
  animation: delayedShow 1s;
  -o-animation: delayedShow 1s;
  -moz-animation: delayedShow 1s;
  -webkit-animation: delayedShow 1s;
}

div.image-gallery div.no-image h4 {
  width: 100%;
}

div.image-gallery div.no-image svg {
  width: 50%;
  height: 50%;
  margin: 1rem;
}

div.image-gallery div.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

div.image-gallery div.expand-icon svg {
  width: 3.5rem;
  height: 3.5rem;
  color: rgb(255, 255, 255);
  opacity: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  padding: 1rem;
  filter: drop-shadow(0.15rem 0.15rem 0.15rem rgb(50 50 50 / 0.6));
  transition: 0.2s;
  cursor: pointer;
}

div.image-gallery div.expand-icon svg:hover {
  opacity: 1;
}

div.image-gallery div.expand-icon svg:active {
  color: rgb(225, 225, 225);
  padding: 1.05rem;
}

div.image-gallery div.full-screen-image-overlay {
  position: fixed;
  background-color: rgb(25, 25, 25, 0.9);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

div.image-gallery div.full-screen-image-overlay div.image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

div.image-gallery img.full-screen-image {
  max-width: 96%;
  max-height: 96%;
  width: auto;
  height: auto;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: block;
}

div.image-gallery
  div.full-screen-image-overlay
  div.contract-icon-container
  svg {
  width: 4.5rem;
  height: 4.5rem;
  padding: 1.25rem;
  color: rgb(255, 255, 255);
  filter: drop-shadow(0.15rem 0.15rem 0.15rem rgb(50 50 50 / 0.6));
  transition: 0.2s;
  opacity: 0.75;
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
}

div.image-gallery
  div.full-screen-image-overlay
  div.contract-icon-container
  svg:hover {
  opacity: 1;
}

div.image-gallery
  div.full-screen-image-overlay
  div.contract-icon-container
  svg:active {
  color: rgb(225, 225, 225);
  padding: 1.3rem;
}

div.image-gallery div.change-photo-icon,
div.image-gallery div.change-photo-icon-full-screen {
  position: absolute;
  top: calc(50% - 3.5rem / 2);
}

div.image-gallery div.next-photo-icon {
  right: 0;
}

div.image-gallery div.change-photo-icon svg,
div.image-gallery div.change-photo-icon-full-screen svg {
  width: 3.5rem;
  height: 3.5rem;
  color: rgb(255, 255, 255);
  filter: drop-shadow(0.15rem 0.15rem 0.15rem rgb(50 50 50 / 0.6));
  transition: 0.2s;
  opacity: 0;
  cursor: pointer;
}

div.image-gallery div.change-photo-icon-full-screen svg {
  opacity: 0.5;
}

div.image-gallery div.change-photo-icon svg:hover,
div.image-gallery div.change-photo-icon-full-screen svg:hover {
  opacity: 1;
}

div.image-gallery div.change-photo-icon svg:active,
div.image-gallery div.change-photo-icon-full-screen svg:active {
  color: rgb(225, 225, 225);
  padding: 0.1rem;
}

div.image-gallery div.main-image-container:hover div.change-photo-icon svg {
  opacity: 0.75;
}

div.image-gallery div.main-image-container:hover div.expand-icon svg {
  opacity: 0.75;
}

@keyframes delayedShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
