footer.page-footer {
  display: flex;
  justify-content: center;
}

div.footer-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  margin: 1.5rem 1rem;
}

div.footer-section {
  width: 100%;
  margin-bottom: 1.5rem;
}

.footer-section svg {
  margin-right: 0.5rem;
}

dt {
  float: left;
  width: 1.5rem;
  padding: 0;
  margin: 0;
}
dd {
  float: left;
  width: calc(100% - 1.5rem);
  padding: 0;
  margin: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.footer-section {
    width: 48%;
    margin-bottom: 1.5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
