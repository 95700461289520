div.product-list.container-admin
  div.products-container
  div.product-card-container,
div.container-admin {
  -moz-box-shadow: 0 0 1rem #ccc;
  -webkit-box-shadow: 0 0 1rem #ccc;
  box-shadow: 0 0 1rem #ccc;
  overflow-x: hidden;
  position: relative;
}

.starter-template {
  padding: 3rem 1.5rem;
}

div.container-admin {
  width: 95%;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  padding-top: 1rem;
}

footer.admin-spacer {
  height: 5rem;
}

.form-group {
  margin: 1rem 0;
}

.btn-dashboard-menu {
  width: 90%;
  height: 4rem;
  margin: 1rem;
}

.button-icon {
  margin: 0 0.5em;
}

.code-block-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: stretch;
}

.code-block-card .card {
  flex: 1;
}

.code-block-card button {
  margin: 0 0.2rem;
}

.m-r-1em {
  margin-right: 1em;
  margin: 0.2rem;
}
.m-b-1em {
  margin-bottom: 1em;
}
.m-l-1em {
  margin-left: 1em;
}
.mt0 {
  margin-top: 0;
}

.page-section {
  margin: 0.4em 0;
}

#btn-div {
  margin: 1rem auto;
  float: left;
  display: flex;
  justify-content: space-between;
}

#btn-div > button {
  margin-right: 0.2rem;
}

.container-admin div.main-content {
  margin: 2rem 0;
}

div.dropdown-buttons div.category-dropdown {
  margin: 0.25rem 0;
}

div.dropdown-buttons div.condition-dropdown {
  margin: 0.25rem 0;
}

td.description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

table {
  border-collapse: separate;
  border-spacing: 1rem;
}

td {
  padding-right: 0.5rem;
}

p.description {
  white-space: pre-line;
}

div.page-header div.header-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.page-header div.header-title-bar div.title h1 {
  margin: 0;
  padding: 0;
}

div.page-header div.header-title-bar div.buttons svg {
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0;
}

svg.button-icon {
  margin: 0 0.5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
