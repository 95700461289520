.latest-products div.main-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.latest-products div.button-div {
  width: 100%;
  display: flex;
  justify-content: right;
}

.latest-products div.main-content div.card {
  width: 18rem;
  margin: 0.5rem;
}

.products-page div.content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.products-page div.content div.card {
  width: 18rem;
  margin: 0.5rem;
}

.products-page div.title-bar {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.products-page div.title-bar div.title h5 {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.products-page div.title-bar div.filters {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.products-page div.title-bar div.filters svg {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  transition: 0.2s;
}

.products-page div.title-bar div.filters div.icon {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  border-radius: 0.4rem;
  background-color: rgb(225, 225, 225);
  overflow: hidden;
  transition: box-shadow 0.1s;
}

.products-page div.title-bar div.filters div.icon-active {
  -moz-box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
  -webkit-box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
  box-shadow: 0 0 0 0.2rem rgb(100, 150, 200);
}

.products-page div.title-bar div.filters svg:hover {
  padding: 0.45rem;
  background-color: rgb(235, 235, 235);
}

.products-page div.title-bar div.filters svg:active {
  background-color: rgb(215, 215, 215);
  padding: 0.55rem;
}

div.product-card {
  width: 100%;
  margin: 1rem 0;
}

div.card-image {
  width: 100%;
  height: auto;
  aspect-ratio: auto 4 / 3;
  overflow: hidden;
  display: flex;
  margin-bottom: 0.2rem;
  background-color: rgb(250, 250, 250);
  position: relative;
}

img.card-image {
  margin: auto;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  position: absolute;
}

div.product-card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.product-card-content span {
  width: 100%;
}

.product-card-content span.title {
  color: rgb(100, 100, 100);
}

.product-card-content span.price {
  font-weight: bold;
}

.product-card-content .content-loader {
  width: 100%;
  height: 2.9rem;
}

section.product-preview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  padding-top: 1rem;
  margin: 1rem auto;
}

section.product-preview div.pane {
  width: 100%;
  margin-bottom: 1rem;
}

section.product-preview div.info-pane h3 {
  color: rgb(50, 50, 50);
  margin-top: -0.45rem;
}

section.products-page div.filtering-container {
  position: relative;
  margin-top: 1rem;
}

section.product-preview div.condition-container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

section.product-preview div.condition-container span {
  width: 100%;
  margin: 0.2rem 0;
}

section.product-preview p.description {
  margin-top: 1rem;
  white-space: pre-line;
  background-color: rgb(245, 245, 245);
  padding: 1rem;
  border-radius: 0.5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  section.product-preview {
    margin: 5rem auto;
  }

  section.product-preview div.pane {
    width: 49%;
  }

  div.product-card {
    width: 24rem;
    margin: 0.5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
