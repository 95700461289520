div.no-products-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  color: rgb(150, 150, 150);
}

div.no-products-container h4 {
  width: 100%;
}
div.no-products-container svg {
  width: 18rem;
  height: 18rem;
  margin: 1rem;
}

section.not-found-error div.content {
  margin: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: rgb(100, 100, 100);
}

section.not-found-error div.content div.error-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
}

section.not-found-error div.content h1 {
  text-align: center;
}
section.not-found-error div.content div.error-icon svg {
  width: 15rem;
  height: 15rem;
}

section.not-found-error div.content div.error-page-button {
  margin-top: 5rem;
  display: flex;
  width: 100%;
  align-items: flex-start;
}
