header.header-header {
  width: 100%;
}

#header-navbar a.nav-link {
  font-weight: bold;
}

nav.navbar {
  padding: 1rem 1rem;
}
